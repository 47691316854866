<template>
  <div class="about">
    <div class="logo">
      <img class="logoImg" src="@/assets/images/menu/logo.svg" alt="" />
      <div class="text">{{ $t("common.title") }}</div>
    </div>

    <div class="item">
      <div class="tit">{{ $t("seeting.version") }}</div>
      <div class="text">v1.1.44</div>
    </div>
    <div class="item" @click="go">
      <div class="tit">{{ $t("seeting.agreement") }}</div>
      <img class="rightImg" src="../../../assets/images/right.png" alt="" />
    </div>
    <div class="item">
      <div class="tit">{{ $t("seeting.telephone") }}</div>
      <a class="phone" href="tel:400-114-9100">400-114-9100</a>
    </div>
    <div class="button">
      <van-button size="large" @click="back" class="btn" type="default">{{
        $t("common.back")
      }}</van-button>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {

    };
  },

  mounted() {},

  methods: {
    go() {
      this.$router.push('agreement');
    },
   
    back() {
      this.$router.go(-1);
    },

  },
};
</script>
<style scoped lang='less'>
@dialog-message-font-size: 60px;
.about {
  width: 100%;
  height: 100%;
  background: #fafafa;
  overflow: hidden;
  .logo {
    width: 100%;
    height: 320px;
    background: #ffffff;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logoImg {
      width: 128px;
      height: 128px;
      margin-top: 72px;
    }
    .text {
      font-size: 28px;
      color: #333;
    }
  }
  .rightImg {
    width: 32px;
    height: 32px;
  }
  .button {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    margin-top: 80px;
    .btn {
      height: 88px;
    }
  }

  .item {
    background: #fff;
    margin-top: 16px;
    height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .tit {
      font-size: 32px;
      color: #333;
    }
    .text {
      font-size: 32px;
      color: #333;
    }
    .phone{
        font-size:32px;
        color:#1AB370;
    }
  }
}
</style>
